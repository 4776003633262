import React from 'react';
import {useOutlet} from 'reconnect.js';
import * as User from 'rev.sdk.js/Actions/User';
import Config from '../../../data.json';
import AdminLayout from '../AdminLayout';
import SiteNavBar from '../SiteNavBar';
import ProfileLayout from '../ProfileLayout';
import SiteFooter from '../SiteFooter';
import CheckoutLayout from '../CheckoutLayout';
import useFacebookPixel from '../../Hooks/useFacebookPixel';
import useGoogleAnalytics from '../../Hooks/useGoogleAnalytics';
import useLayoutInit from '../../Hooks/useLayoutInit';
import PleaseWait from '../PleaseWait';
import useIsInApp from 'rev.sdk.js/Hooks/useIsInApp';
import useSiteNavBar from '../../Hooks/useSiteNavBar';
import useSiteFooter from '../../Hooks/useSiteFooter';
import * as CustomLayout from '../../Utils/CustomLayout';

function Layout(props) {
  const {children, location} = props;
  const isInApp = useIsInApp();
  const [initialized, setInitialized] = React.useState(false);

  useFacebookPixel({pixelId: Config.pixelId});
  useGoogleAnalytics({gaId: Config.gaId, gtmId: Config.gtmId});

  const {extraElement} = useLayoutInit(props);
  const {showNavbar} = useSiteNavBar({
    location: props.location,
    pageContext: props.pageContext,
  });
  const {showFooter} = useSiteFooter({
    location: props.location,
    pageContext: props.pageContext,
  });

  React.useEffect(() => {
    const isAdmin = location.pathname.indexOf('admin') !== -1;

    const initialize = async (isAdmin) => {
      let result = false;

      try {
        result = await User.autoLogin({admin: isAdmin});
      } catch (ex) {
        console.log('autoLogin ex', ex);
        User.logout();
      }

      if (Config.allowAnonymousCheckout && !result) {
        await User.tempLogin();
      }

      setInitialized(true);
    };

    if (!initialized) {
      initialize(isAdmin);
    }
  }, [location.pathname, initialized]);

  React.useEffect(() => {
    const isAdmin = location.pathname.indexOf('admin') !== -1;

    if (initialized && isAdmin) {
      let timer = setInterval(async () => {
        console.log('auto refresh access token');
        try {
          await User.autoLogin({admin: isAdmin});
        } catch (ex) {
          console.log('autoLogin ex', ex);
          User.logout();
        }
      }, 7080000); // 1 hour 58 minute

      return () => clearInterval(timer);
    }
  }, [location.pathname, initialized]);

  if (location.pathname.indexOf('admin') !== -1) {
    if (!initialized) {
      return <PleaseWait title="自動登入中" />;
    }

    return (
      <>
        {CustomLayout.renderHtmlHelmet({location})}
        <AdminLayout location={location}>{children}</AdminLayout>
      </>
    );
  } else if (location.pathname.indexOf('profile') !== -1) {
    if (!initialized) {
      return <PleaseWait title="自動登入中" />;
    }

    return (
      <>
        {CustomLayout.renderHtmlHelmet({location})}
        <SiteNavBar {...props} />
        <ProfileLayout style={{flex: 1}} location={location}>
          {children}
        </ProfileLayout>
        <SiteFooter {...props} />
      </>
    );
  } else if (location.pathname.indexOf('checkout') !== -1) {
    if (!initialized) {
      return <PleaseWait title="自動登入中" />;
    }

    return (
      <>
        {CustomLayout.renderHtmlHelmet({location})}
        {!isInApp && <SiteNavBar {...props} />}
        <CheckoutLayout style={{flex: 1}} location={location}>
          {children}
        </CheckoutLayout>
        {!isInApp && <SiteFooter {...props} />}
      </>
    );
  }

  return (
    <>
      {CustomLayout.renderHtmlHelmet({location})}
      {showNavbar && <SiteNavBar {...props} />}
      <div style={{flex: 1}}>{children}</div>
      {showFooter && <SiteFooter {...props} />}
      {extraElement}
    </>
  );
}

export default Layout;
