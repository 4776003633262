import {getNewOutlet} from 'reconnect.js';
import {buildCatDisplayMap} from 'rev.sdk.js/Utils/buildCatDisplayMap';

const storeCategories = [
  {name: 'personality', display: '個性必訪'},
  {name: 'latte', display: '拿鐵之王'},
  {name: 'netredt', display: '網紅好拍'},
  {name: 'champion', display: '冠軍朝聖'},
  {name: 'dessert', display: '甜點稱王'},
  {name: 'takeout', display: '外帶嚴選'},
];

getNewOutlet('extra', {}, {autoDelete: false});
getNewOutlet('storeCategories', storeCategories, {autoDelete: false});