/*  __CODEGEN_START__ */ 
import * as Cart from 'rev.sdk.js/Actions/Cart';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
/* __CODEGEN_END__  */

const AppActionsOverride = {};

//////////////////////////////////////////////////
// Override existing AppActions
AppActionsOverride.doCustomThing = async (...args) => {
  console.log(args);
};

/*  __CODEGEN_START__ */ 
AppActionsOverride.clientJStorageFetch = async (...args) => {
  const collection = args[0];
  const {cat, sort, search, q, page} = args[1];

  //"q" can defined custom query by project
  const catQuery = cat ? {labels: {$regex: cat}} : {};
  const searchQuery = search ? {searchText: {$regex: search}} : {};
  const sortValue = sort ? [sort] : ['-created'];
  let extraQueries = {};
  let projection = null;

  if (collection === 'product') {
    extraQueries = q
      ? q.indexOf('flavor') > -1
        ? (() => {
            let flavorQuery = [];
            let flavorStringToNumber = q
              .split('-')
              .filter((_, index) => index > 0)
              .map((element) => {
                return Number(element) || 0;
              });

            flavorStringToNumber.forEach((element, index) => {
              if (index === 0) {
                element > 0 && flavorQuery.push({bitter: true});
              } else if (index === 1) {
                element > 0 && flavorQuery.push({acidity: true});
              } else if (index === 2) {
                element > 0 && flavorQuery.push({sweet: true});
              } else if (index === 3) {
                element > 0 && flavorQuery.push({body: true});
              } else if (index === 4) {
                element > 0 && flavorQuery.push({aftertaste: true});
              }
            });

            return {
              $or: flavorQuery,
            };
          })()
        : extraQueries
      : {};
    extraQueries.public = true;
  } else if (collection === 'Article_Default') {
    delete catQuery.labels;
    if (!cat) {
      catQuery.label = 'blog';
    } else {
      catQuery.label = {$regex: cat};
    }
    projection = {content: 0};
  }

  const resp = await JStorage.fetchDocuments(
    collection,
    {
      ...catQuery,
      ...searchQuery,
      ...extraQueries,
    },
    sortValue,
    page,
    projection, // if we're fetching Article, ignore the content
    {anonymous: true},
  );

  return resp;
};
/* __CODEGEN_END__  */

export default AppActionsOverride;

//////////////////////////////////////////////////
// Extra custom actions
function sayHello() {
  console.log('hello, this is custom section, custom it');
}

export {sayHello};
